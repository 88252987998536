export const TIME_SLOTS = {
  '00:00': { value: '00:00', label: '12:00 AM' },
  '00:30': { value: '00:30', label: '12:30 AM' },
  '01:00': { value: '01:00', label: '1:00 AM' },
  '01:30': { value: '01:30', label: '1:30 AM' },
  '02:00': { value: '02:00', label: '2:00 AM' },
  '02:30': { value: '02:30', label: '2:30 AM' },
  '03:00': { value: '03:00', label: '3:00 AM' },
  '03:30': { value: '03:30', label: '3:30 AM' },
  '04:00': { value: '04:00', label: '4:00 AM' },
  '04:30': { value: '04:30', label: '4:30 AM' },
  '05:00': { value: '05:00', label: '5:00 AM' },
  '05:30': { value: '05:30', label: '5:30 AM' },
  '06:00': { value: '06:00', label: '6:00 AM' },
  '06:30': { value: '06:30', label: '6:30 AM' },
  '07:00': { value: '07:00', label: '7:00 AM' },
  '07:30': { value: '07:30', label: '7:30 AM' },
  '08:00': { value: '08:00', label: '8:00 AM' },
  '08:30': { value: '08:30', label: '8:30 AM' },
  '09:00': { value: '09:00', label: '9:00 AM' },
  '09:30': { value: '09:30', label: '9:30 AM' },
  '10:00': { value: '10:00', label: '10:00 AM' },
  '10:30': { value: '10:30', label: '10:30 AM' },
  '11:00': { value: '11:00', label: '11:00 AM' },
  '11:30': { value: '11:30', label: '11:30 AM' },
  '12:00': { value: '12:00', label: '12:00 PM' },
  '12:30': { value: '12:30', label: '12:30 PM' },
  '13:00': { value: '13:00', label: '1:00 PM' },
  '13:30': { value: '13:30', label: '1:30 PM' },
  '14:00': { value: '14:00', label: '2:00 PM' },
  '14:30': { value: '14:30', label: '2:30 PM' },
  '15:00': { value: '15:00', label: '3:00 PM' },
  '15:30': { value: '15:30', label: '3:30 PM' },
  '16:00': { value: '16:00', label: '4:00 PM' },
  '16:30': { value: '16:30', label: '4:30 PM' },
  '17:00': { value: '17:00', label: '5:00 PM' },
  '17:30': { value: '17:30', label: '5:30 PM' },
  '18:00': { value: '18:00', label: '6:00 PM' },
  '18:30': { value: '18:30', label: '6:30 PM' },
  '19:00': { value: '19:00', label: '7:00 PM' },
  '19:30': { value: '19:30', label: '7:30 PM' },
  '20:00': { value: '20:00', label: '8:00 PM' },
  '20:30': { value: '20:30', label: '8:30 PM' },
  '21:00': { value: '21:00', label: '9:00 PM' },
  '21:30': { value: '21:30', label: '9:30 PM' },
  '22:00': { value: '22:00', label: '10:00 PM' },
  '22:30': { value: '22:30', label: '10:30 PM' },
  '23:00': { value: '23:00', label: '11:00 PM' },
  '23:30': { value: '23:30', label: '11:30 PM' },
};
