import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import FormControl from '@material-ui/core/FormControl';
import TypableSelect from './../../helpers/TypableSelect';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { addUser } from '../../actions/AdminUserAction';
import { AllCompany, countryDataList, CompanyDetailById } from '../../actions/CompanyAction';
import config from './../../constants/config';
import IntegrationDownshift from './../../helpers/multiSelect';
import { locationList } from '../../actions/LocationAction';
import validator from './../../helpers/checkValidations';
import LoadingOverlay from 'react-loading-overlay';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { changeHandler } from './CommonFunctions';
import { getValidationStyle, handleChangeCheckbox, getFeeSettingPermissionTitle } from '../Users/CommonFunction';

const filter = createFilterOptions();

const mapToFormatCountry = (cValues) => {
  return cValues.map((Obj) => ({ value: Obj.Country, label: Obj.Country }));
};
const mapToFormatStates = (states) => {
  return Object.keys(states).map((name) => ({ value: name, label: name }));
};

const mapToFormatCity = (cities) => {
  return cities.map((name) => ({ value: name, title: name }));
};

class AddUserCompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: config.ADMIN_ROLE,
      sideShow: true,
      isLoading: true,
      isLocation: false,
      company: {},
      companyData: '',
      currentCompanyData: {},
      misMatchError: [],
      isSubmit: false,
      locationSelected: [],
      data: [],
      enableBatchTransactionReportNotification: false,
      permissions: {
        creatNewUser: true,
        editCustomer: true,
        exportRecord: true,
        refunds: true,
        refundsManual: true,
        allLocaton: true,
        createInvoice: true,
        textToInvoice: true,
        textInvoice: true,
        textNotifications: true,
        ach: true,
        virtualTerminal: true,
        recurring: true,
        feeEdit: true,
        void: true,
        emailNotification: true,
        deleteInvoice: true,
        viewAllTransactions: true,
        emailVisibilityTerminal: true,
        notesVisibilityTerminal: true,
        displayProducts: true,
        displayDashboard: true,
        displaySettings: true,
        displayTickets: true,
        displayLocations: true,
        displayUsers: true,
        displayTransactions: true,
      },
      location: {
        locationName: '',
        email: '',
        secretKey: '',
        phone: '',
        contactName: '',
        address1: '',
        address2: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        timeZone: new Date(),
        merchantId: '',
        gatewayMerchantId: '',
        notes: '',
        terminalId: '',
        terminalNumber: '',
        batchItem: '',
      },
      stateList: {},
      cityList: [],
      Countrydata: [],
      locationError: {
        nameError: false,
        secretKeyError: false,
        gatewayMerchantIdError: false,
        locationNameError: false,
        phoneError: false,
        emailError: false,
        contactNameError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
      },
      error: {
        UserNameError: false,
        phoneError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
      },
      marginBottom: '10',
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.getLocationData();
    this.fetchCompanyData();
    this.fetchCountryDataList();
    this.getCompanyData();
    let dbaName = this.props.dbaName;
    const {
      match: { params },
    } = this.props.props;

    this.setState({
      company: { ...this.state.company, value: params.id, label: dbaName },
      location: { ...this.state.location, merchantId: params.id },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.locationSelected !== this.state.locationSelected) {
      this.updateMarginBottom();
      getValidationStyle(!this.state.locationSelected && this.state.isSubmit, this.state.marginBottom);
    }
  }

  updateMarginBottom() {
    const container = this.containerRef.current;
    if (container.scrollHeight > container.clientHeight) {
      this.setState({ marginBottom: container.scrollHeight });
    }
  }

  fetchCountryDataList() {
    this.setState({ isLoading: true });
    this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false });
    });
  }
  fetchCompanyData() {
    this.setState({ isLoading: true });
    this.props.AllCompany().then((res) => {
      if (res.payload.companyData.status === 200)
        this.setState({ companyData: res.payload.companyData.data.response, isLoading: false });
    });
  }

  getCompanyData() {
    const {
      match: { params },
    } = this.props.props;
    this.setState({ isLoading: true });
    this.props.CompanyDetailById(params.id).then((res) => {
      this.setState({ currentCompanyData: res.payload.companyData.data.response });
    });
  }
  getLocationData() {
    const {
      match: { params },
    } = this.props.props;
    let merchant = params.id;
    let data = { companyId: merchant };
    this.props.locationList(data).then((res) => {
      this.setState({ data: res.payload.locationData.data.response, isLoading: false });
      let data = this.state.data;
      if (data && data.length > 0) this.setState({ locationSelected: Object.keys(data).map((k, _v) => data[k].id) });
    });
  }
  addNewLocation(_e) {
    if (this.state.isLocation) this.setState({ isLocation: false });
    else this.setState({ isLocation: true });
  }
  handleClickMenu(_e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    window.location.reload();
  }
  onhandleChangeCheckboxes(event) {
    const permissions = handleChangeCheckbox(event, this.state);
    this.setState({ permissions });
  }

  handleChangeLocation(event, _t) {
    let error = {};
    let successMessages = false;
    let locationError = {};
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    validator(id, value) ? (locationError[id + 'Error'] = true) : (locationError[id + 'Error'] = false);

    this.setState({
      location: { ...this.state.location, [id]: value },
      error,
      successMessages,
      misMatchError,
      locationError,
      isSubmit: false,
    });
  }
  handleChange = (event, _t) => {
    const { id, value, misMatchError, error, validationMarkup } = changeHandler(event);
    if (!validationMarkup) {
      return false;
    }
    this.setState({
      [id]: value,
      error,
      misMatchError,
      isSubmit: false,
      successMessages: false,
    });
  };

  handleSubmit(e, _t) {
    let misMatchError = [];
    let successMessages = false;
    let locationState = this.state.location;
    let state = this.state;
    let error = {};
    let locationError = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });
    if (locationState.address2 === '') {
      delete locationState.address2;
    }
    if (locationState.notes === '') {
      delete locationState.notes;
    }
    if (this.state.isLocation) {
      Object.keys(locationState).map((key, _value) => {
        if (validator(key, locationState[key])) {
          return validator(key, locationState[key])
            ? ((locationError[key + 'Error'] = true), (errorFlag = true))
            : (locationError[key + 'Error'] = false);
        } else {
          return locationState[key] === '' ||
            (typeof locationState[key] === 'string' && locationState[key].trim() === '')
            ? ((locationError[key + 'Error'] = true), (errorFlag = true))
            : '';
        }
      });
    }
    Object.keys(state).map((keyValue, _value) => {
      if (keyValue === 'phoneNumber' || keyValue === 'amount') {
        return false;
      }
      if (
        keyValue === 'phone' &&
        !this.state.permissions.textInvoice &&
        !this.state.permissions.textToInvoice &&
        !this.state.permissions.textFlex
      ) {
        return false;
      }
      if (validator(keyValue, state[keyValue])) {
        return validator(keyValue, state[keyValue])
          ? ((error[keyValue + 'Error'] = true), (errorFlag = true))
          : (error[keyValue + 'Error'] = false);
      } else {
        return state[keyValue] === '' || (typeof state[keyValue] === 'string' && state[keyValue].trim() === '')
          ? ((error[keyValue + 'Error'] = true), (errorFlag = true))
          : '';
      }
    });

    if (errorFlag) {
      window.scrollTo(0, 0);
      this.setState({ misMatchError, error, locationError, successMessages });
      return false;
    } else {
      this.setState({ isLoading: true });
      this.props.addUser(this.state).then((result) => {
        window.scrollTo(0, 0);
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          this.setState({ successMessages: true, isLoading: false });
          setTimeout(() => {
            this.goBack(e);
          }, 3000);
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, isLoading: false, successMessages });
        }
      });
    }
  }
  setCountryStateValue(val) {
    if (val !== null)
      this.setState({
        stateList: this.state.Countrydata.filter((obj) => obj.Country === val.label)[0].State,
        location: { ...this.state.location, country: val.label },
      });
  }
  setStatesSetValue(val) {
    if (val !== null)
      this.setState({
        cityList: this.state.stateList[val.label],
        location: { ...this.state.location, state: val.label },
      });
  }
  /*  setCitySet(val) {
    if (val !== null)

      this.setState({ location: { ...this.state.location, city: val.label } })
  } */

  setCity(event, val) {
    if (val !== null && Object.prototype.hasOwnProperty.call(val, 'value')) {
      this.setState({ location: { ...this.state.location, city: val.title } });
    } else if (val && val.inputValue) {
      this.setState({ location: { ...this.state.location, city: val.inputValue } });
    } else {
      this.setState({ location: { ...this.state.location, city: val } });
    }
  }

  goBack(e) {
    this.props.handleChangeTab(e, 0);
  }

  addLocation = (arr) => {
    let misMatchError = [];
    this.setState({ locationSelected: arr, misMatchError });
  };
  render() {
    const { t } = this.props;
    const { Countrydata, data } = this.state;

    return (
      <LoadingOverlay
        className={classes.tpTableFullW}
        active={this.state.isLoading}
        spinner
        text="Fetching your response ..."
      >
        <Container component="main" maxWidth="md">
          {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
          {/* {this.state.successMessages ?
          <SuccessMessage successes={[this.props.info.userInfo.data.message]} /> : ''
        } */}
          {this.state.successMessages && this.props.info ? (
            <SuccessMessage successes={[this.props.info.userInfo.data.message]} />
          ) : (
            ''
          )}

          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required
                  error={this.state.error.userNameError}
                  id="userName"
                  type="text"
                  labels={t('Username')}
                  value={this.state.userName}
                  width
                  onChange={(e) => this.handleChange(e, t)}
                  focus
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required
                  error={this.state.error.firstNameError}
                  id="firstName"
                  type="text"
                  labels={t('FirstName')}
                  value={this.state.firstName}
                  width
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required
                  error={this.state.error.lastNameError}
                  id="lastName"
                  type="text"
                  labels={t('LastName')}
                  value={this.state.lastName}
                  width
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="email"
                  labels={t('Email')}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Textfield
                  required={
                    this.state.permissions.textInvoice ||
                    this.state.permissions.textToInvoice ||
                    this.state.permissions.textFlex
                  }
                  error={
                    this.state.permissions.textInvoice ||
                    this.state.permissions.textToInvoice ||
                    this.state.permissions.textFlex
                      ? this.state.error.phoneError
                      : false
                  }
                  id="phone"
                  type="phone"
                  labels={t('Phone')}
                  value={this.state.phone}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  style={getValidationStyle(
                    !this.state.locationSelected && this.state.isSubmit,
                    this.state.marginBottom
                  )}
                  ref={this.containerRef}
                >
                  <IntegrationDownshift
                    isLocationExists={true}
                    selectedLocations={data}
                    locationData={data}
                    addLocation={this.addLocation}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Textfield
                  className="Redcursor"
                  required={true}
                  id="dabName"
                  type="text"
                  labels={t('DBAName')}
                  value={this.state.company.label}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              {this.state.isLocation ? (
                <>
                  <Grid item xs={12}>
                    <b>Location Detail</b>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.locationNameError}
                      id="locationName"
                      type="text"
                      labels={t('LocationName')}
                      value={this.state.location.locationName}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.contactNameError}
                      id="contactName"
                      type="text"
                      labels={t('ContactName')}
                      value={this.state.location.contactName}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.emailError}
                      id="email"
                      type="email"
                      labels={t('Email')}
                      value={this.state.location.email}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      helperText="phone format xxx-xxx-xxxx"
                      error={this.state.locationError.phoneError}
                      id="phone"
                      type="number"
                      labels={t('Phone')}
                      value={this.state.location.phone}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.address1Error}
                      id="address1"
                      type="text"
                      labels={t('Address1')}
                      value={this.state.location.address1}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      id="address2"
                      type="text"
                      labels={t('Address2')}
                      value={this.state.location.address2}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3} style={{ marginTop: '1.5rem' }}>
                    <FormControl
                      fullWidth
                      style={getValidationStyle(
                        !this.state.location.country && this.state.isSubmit,
                        this.state.marginBottom
                      )}
                    >
                      <TypableSelect
                        placeholder={'Select country name'}
                        suggestions={mapToFormatCountry(Countrydata)}
                        onChange={(val) => this.setCountryStateValue(val)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ marginTop: '1.5rem' }}>
                    <FormControl
                      fullWidth
                      style={getValidationStyle(
                        !this.state.location.state && this.state.isSubmit,
                        this.state.marginBottom
                      )}
                    >
                      <TypableSelect
                        placeholder={'Select state name'}
                        suggestions={mapToFormatStates(this.state.stateList)}
                        onChange={(val) => this.setStatesSetValue(val)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControl
                      fullWidth
                      style={getValidationStyle(
                        !this.state.location.city && this.state.isSubmit,
                        this.state.marginBottom
                      )}
                    >
                      <Autocomplete
                        value={this.state.city}
                        onChange={(e, value) => this.setCity(e, value)}
                        filterOptions={(options, params) => {
                          const filteredResult = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== '') {
                            filteredResult.push({
                              inputValue: params.inputValue,
                              title: `Add "${params.inputValue}"`,
                            });
                          }
                          return filteredResult;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="city"
                        options={mapToFormatCity(this.state.cityList)}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.title;
                        }}
                        renderOption={(option) => option.title}
                        style={{ width: 199 }}
                        freeSolo
                        renderInput={(param) => <TextField {...param} label="city" />}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={3} style={{ marginTop: '-32px' }}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.zipError}
                      id="zip"
                      type="text"
                      labels={t('Zip')}
                      value={this.state.location.zip}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.merchantIdError}
                      id="merchantId"
                      type="text"
                      labels={t('TitaniumMerchantId')}
                      value={this.state.location.merchantId}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.gatewayMerchantIdError}
                      id="gatewayMerchantId"
                      type="text"
                      labels={t('GatewayMerchantId')}
                      value={this.state.location.gatewayMerchantId}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.terminalIdError}
                      id="terminalId"
                      type="text"
                      labels={t('TerminalId')}
                      value={this.state.location.terminalId}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.terminalNumberError}
                      id="terminalNumber"
                      type="text"
                      labels={t('TerminalNumber')}
                      value={this.state.location.terminalNumber}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={true}
                      error={this.state.locationError.batchItemError}
                      id="batchItem"
                      type="text"
                      labels={t('BatchItem')}
                      value={this.state.location.batchItem}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      helperText="it must contain at least one uppercase, one lower case, one special character and a number,length 8-20"
                      required={true}
                      error={this.state.locationError.secretKeyError}
                      id="secretKey"
                      type="text"
                      labels={t('SecretKey')}
                      value={this.state.location.secretKey}
                      width={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Textfield
                      id="notes"
                      type="text"
                      labels={t('Notes')}
                      value={this.state.location.notes}
                      width={true}
                      rowsMax={'2'}
                      multiline={true}
                      onChange={(e) => this.handleChangeLocation(e, t)}
                    />
                  </Grid>
                </>
              ) : (
                ''
              )}

              <Grid item xs={6}></Grid>
            </Grid>

            <Grid item xs={12} style={{ marginLeft: -10, marginBottom: 20 }}>
              <label>
                <Checkbox
                  id="enableBatchTransactionEmailNotification"
                  color="primary"
                  onChange={(event) => {
                    this.setState({ enableBatchTransactionReportNotification: event.target.checked });
                  }}
                  checked={this.state.enableBatchTransactionReportNotification}
                  value={this.state.enableBatchTransactionReportNotification}
                  inputProps={{
                    'aria-label': 'secondary checkboxes',
                  }}
                />
                <span>{t('BatchTransactionNotification')}</span>
              </label>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                {' '}
                <h5>Permissions</h5>
              </Grid>
              <Grid container item xs={12}>
                {this.state.role < 4 ? (
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="creatNewUser"
                        labels="creatNewUser"
                        checked={this.state.permissions.creatNewUser}
                        onChange={(e) => {
                          this.onhandleChangeCheckboxes(e);
                        }}
                        value={this.state.creatNewUser}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Create New User</span>
                    </label>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="refunds"
                      checked={this.state.permissions.refunds}
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.refunds}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Refunds General</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="editCustomer"
                      checked={this.state.permissions.editCustomer}
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.editCustomer}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Edit Customer</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      color="primary"
                      id="refundsManual"
                      checked={this.state.permissions.refundsManual}
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                      value={this.state.refundsManual}
                    />
                    <span>Refunds Manual</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.allLocaton}
                      id="allLocaton"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.allLocaton}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>All Location</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.createInvoice}
                      id="createInvoice"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.createInvoice}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Invoicing</span>
                  </label>
                </Grid>
                {this.state.currentCompanyData.ACH && (
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        checked={this.state.permissions.ach}
                        id="ach"
                        onChange={(e) => {
                          this.onhandleChangeCheckboxes(e);
                        }}
                        value={this.state.ach}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>ACH </span>
                    </label>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.virtualTerminal}
                      id="virtualTerminal"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.virtualTerminal}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Virtual Terminal</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.recurring}
                      id="recurring"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.recurring}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Recurring Billing</span>
                  </label>
                </Grid>
                {this.state.permissions.feeEdit}
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.feeEdit}
                      id="feeEdit"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.feeEdit}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>{getFeeSettingPermissionTitle(this.state.role)}</span>
                  </label>
                </Grid>
                <Grid item xs={11} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.viewAllTransactions}
                      id="viewAllTransactions"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.permissions.viewAllTransactions}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>View All Transactions</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.exportRecord}
                      id="exportRecord"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.exportRecord}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Export Records</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.void}
                      id="void"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.void}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Void</span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.emailNotification}
                      id="emailNotification"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.emailNotification}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>{t('emailNotifications')}</span>
                  </label>
                </Grid>

                <Grid item xs={11} sm={6}>
                  <label>
                    <Checkbox
                      checked={this.state.permissions.deleteInvoice}
                      id="deleteInvoice"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      value={this.state.deleteInvoice}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>{t('DeleteInvoice')}</span>
                  </label>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="textToInvoice"
                        color="primary"
                        onChange={(event) => {
                          this.onhandleChangeCheckboxes(event);
                        }}
                        checked={this.state.permissions.textToInvoice}
                        value={this.state.permissions.textToInvoice}
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Texting</span>
                    </label>
                  </Grid>
                  <Grid style={{ marginLeft: 10 }} item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        color="primary"
                        id="textNotifications"
                        onChange={(event) => {
                          this.onhandleChangeCheckboxes(event);
                        }}
                        checked={this.state.permissions.textNotifications}
                        value={this.state.permissions.textNotifications}
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Text Notifications</span>
                    </label>
                  </Grid>
                  <Grid style={{ marginLeft: 10 }} item xs={12} sm={5}>
                    <label>
                      <Checkbox
                        color="primary"
                        id="textInvoice"
                        onChange={(event) => {
                          this.onhandleChangeCheckboxes(event);
                        }}
                        checked={this.state.permissions.textInvoice}
                        value={this.state.permissions.textInvoice}
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Text Invoice</span>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              {' '}
              <h4>Terminal Field Display</h4>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="emailVisibilityTerminal"
                      color="primary"
                      onChange={(event) => {
                        this.onhandleChangeCheckboxes(event);
                      }}
                      checked={this.state.permissions.emailVisibilityTerminal}
                      value={this.state.permissions.emailVisibilityTerminal}
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Email</span>
                  </label>
                  <label>
                    <Checkbox
                      id="notesVisibilityTerminal"
                      color="primary"
                      onChange={(event) => {
                        this.onhandleChangeCheckboxes(event);
                      }}
                      checked={this.state.permissions.notesVisibilityTerminal}
                      value={this.state.notesVisibilityTerminal}
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Notes</span>
                  </label>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <h5>Main Menu Display</h5>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="displayDashboard"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      checked={this.state.permissions.displayDashboard}
                      value={this.state.permissions.displayDashboard}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Dashboard</span>
                  </label>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="displayProducts"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      checked={this.state.permissions.displayProducts}
                      value={this.state.permissions.displayProducts}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Products</span>
                  </label>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="displaySettings"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      checked={this.state.permissions.displaySettings}
                      value={this.state.permissions.displaySettings}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Settings</span>
                  </label>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="displayTickets"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      checked={this.state.permissions.displayTickets}
                      value={this.state.permissions.displayTickets}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Tickets</span>
                  </label>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="displayLocations"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      checked={this.state.permissions.displayLocations}
                      value={this.state.permissions.displayLocations}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Locations</span>
                  </label>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="displayTransactions"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      checked={this.state.permissions.displayTransactions}
                      value={this.state.permissions.displayTransactions}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Transactions</span>
                  </label>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <label>
                    <Checkbox
                      id="displayUsers"
                      onChange={(e) => {
                        this.onhandleChangeCheckboxes(e);
                      }}
                      checked={this.state.permissions.displayUsers}
                      value={this.state.permissions.displayUsers}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Users</span>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Buttons
                id="signUp"
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => this.handleSubmit(e, t)}
                text={t('Save')}
              />
              <Buttons
                className={classes.ml_2}
                variant="contained"
                color="secondary"
                text={t('Back')}
                onClick={(e) => this.goBack(e)}
              />
              <Buttons
                className={classes.ml_2}
                variant="contained"
                color={!this.state.isLocation ? 'primary' : 'secondary'}
                text={!this.state.isLocation ? t('AddNewLocation') : t('RemoveNewLocation')}
                onClick={(e) => this.addNewLocation(e, t)}
              />
            </Grid>
          </form>
        </Container>
      </LoadingOverlay>
    );
  }
}
AddUserCompanyDetails.propTypes = {
  t: PropTypes.func,
  dbaName: PropTypes.string,
  countryDataList: PropTypes.func,
  match: PropTypes.any,
  info: PropTypes.any,
  handleChangeTab: PropTypes.func,
  addUser: PropTypes.func,
  props: PropTypes.any,
  locationList: PropTypes.func,
  AllCompany: PropTypes.func,
  CompanyDetailById: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { addUser, AllCompany, countryDataList, locationList, CompanyDetailById })(
  withTranslation()(AddUserCompanyDetails)
);
