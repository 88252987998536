import { validateHtmlMarkUp } from '../../helpers/commonFunction';
import validator from '../../helpers/checkValidations';
import config from './../../constants/config';

export function changeHandler(event, state) {
  let error = {};
  let misMatchError = [];
  let validationMarkup = true;
  let permissions = { ...state.permissions };
  let validatePhone = true;
  let { id, value } = event.target;
  const isHtmlMarkupValid = validateHtmlMarkUp(value);
  if (isHtmlMarkupValid) {
    validationMarkup = false;
  }
  if (
    id === 'perTransactionLimit' ||
    id === 'dailyTransactionLimit' ||
    id === 'perRefundLimit' ||
    id === 'dailyRefundLimit'
  ) {
    permissions = { ...state.permissions, [id]: value };
  }
  validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
  return { id, value, misMatchError, error, validationMarkup, permissions, validatePhone };
}

export const subAdminInitialState = {
  userName: '',
  firstName: '',
  lastName: '',
  email: '',
  enableBatchTransactionReportNotification: false,
  permissions: {
    addLocation: true,
    editLocation: true,
    isActiveLocation: true,
    addUser: true,
    editUser: true,
    isActiveUser: true,
    addCompany: true,
    editCompany: true,
    isActiveCompany: true,
  },
  role: config.SUB_ADMIN_ROLE,
  misMatchError: [],
  error: {
    UserNameError: false,
    firstNameError: false,
    lastNameError: false,
    emailError: false,
  },
};

export const getValidationStyle = (cond, marginBtm) => {
  if (cond) {
    return {
      width: '100%',
      border: '2px solid red',
      height: '4rem',
      marginBottom: `${marginBtm}px`,
      marginLeft: '2px',
    };
  } else {
    return { width: '100%', marginBottom: `${marginBtm}px`, marginLeft: '2px' };
  }
};

export function handleClickMenu(state) {
  let sideShow = true;
  if (state.sideShow) {
    sideShow = false;
    return sideShow;
  } else return sideShow;
}
export function handleClickRefresh() {
  window.location.reload();
}
export function handleChangeCheckbox(event, state) {
  let permissions = {};
  permissions = { ...state.permissions, [event.target.id]: event.target.checked };
  if (event.target.id === 'textToInvoice') {
    permissions.textInvoice = event.target.checked;
    permissions.textNotifications = event.target.checked;
    permissions.textFlex = event.target.checked;
  }
  return permissions;
}

export const getFeeSettingPermissionTitle = (role) => {
  if ([config.REGULAR_USER_ROLE, config.MANAGER_ROLE].includes(role)) {
    return 'Settings';
  }
  return 'Fee Edit';
};
